'use client';

import CommonMenu from '@/components/layouts/CommonMenu/CommonMenu';
import { useIsOpenSerialCodeModal } from '@/features/serialCode/state';
import { signOut, useSession } from 'next-auth/react';
import React from 'react';
import { postSignout } from '../../api/postSignout';

function MenuWrapper({
  isOptionNotEntered = false,
  hasPendingEntry = false,
  hasAlertEcItem = false,
  isFamikuji = false,
}: {
  isOptionNotEntered?: boolean;
  hasPendingEntry?: boolean;
  hasAlertEcItem?: boolean;
  isFamikuji?: boolean;
}) {
  const session = useSession();
  const [, setIsOpen] = useIsOpenSerialCodeModal();

  const user = session.data?.user;

  const openSerialCode = () => {
    setIsOpen(true);
  };

  const logout = async () => {
    try {
      await postSignout();
    } finally {
      // 仮にエラーが起きてても強制ログアウト
      signOut();
    }
  };

  return (
    <>
      <CommonMenu
        onSerialCode={openSerialCode}
        onLogout={logout}
        userCoin={user?.coin}
        userName={user?.name}
        userUnreadMessageBoxCount={user?.unreadMessageBoxCount}
        isOptionNotEntered={isOptionNotEntered}
        hasPendingEntry={hasPendingEntry}
        hasAlertEcItem={hasAlertEcItem}
        isFamikuji={isFamikuji}
      />
    </>
  );
}

export default MenuWrapper;
