import { type DateFormat, dateFns } from '@/lib/dateFns';
import { newDate } from '@/utils/date';
import { formatInTimeZone } from 'date-fns-tz';

/**
 * 汎用日時フォーマッター
 * @param value
 * @returns
 */
export const formatDateCommon = (value: string | Date, format: DateFormat, isJST = false) => {
  if (!value) return '';
  if (typeof value === 'string') {
    return isJST ? formatInTimeZone(newDate(value), 'Asia/Tokyo', format) : dateFns.format(newDate(value), format);
  }
  return isJST ? formatInTimeZone(value, 'Asia/Tokyo', format) : dateFns.format(value, format);
};

/**
 * 日時フォーマッター
 * yyyy年 mm月上旬 || 中旬 || 下旬の表記
 * @param value
 * @returns
 */
export const formatDateAboutJa = (value: string | Date) => {
  if (!value) return '';

  let targetDate: Date;
  if (typeof value === 'string') {
    targetDate = newDate(value);
  } else {
    targetDate = value;
  }

  const year = targetDate.getFullYear();
  const month = targetDate.getMonth() + 1; //date-fnsでは月は0始まり

  const baseText = `${year}年 ${month}月`;
  const date = targetDate.getDate();
  if (date >= 1 && date < 11) {
    return `${baseText}上旬`;
  }
  if (date >= 11 && date < 21) {
    return `${baseText}中旬`;
  }
  return `${baseText}下旬`;
};

/**
 * 数値フォーマッター（金額など）
 * @param value
 * @param blank2Zero
 * @returns
 */
export const formatCurrency = (value: string | number, blank2Zero?: boolean) => {
  if (value == null) {
    return blank2Zero ? '0' : '';
  }
  if (typeof value === 'string') {
    return Number.parseInt(value, 10).toLocaleString();
  }
  return value.toLocaleString();
};
